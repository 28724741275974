import React from "react"
import { Route, Routes } from "react-router-dom"
import Home from "./pages/home"
import Contact from "./pages/contact"
import About from "./pages/about"
import NotFound from "./pages/notFound"

export default () =>
    <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/contact" exact element={<Contact />} />
        
        <Route path= "*" element={<NotFound />} />
    </Routes>

