import React from "react";

interface Youtube {
  embedID: string
}

const YoutubeEmbed: React.FC<Youtube> = (props: Youtube) => {
    return (
    <div className="video-container">
      <iframe
        src={`https://www.youtube.com/embed/${props.embedID}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  )};

export default YoutubeEmbed