import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, useGlobalState } = createGlobalState({
  mainBackgroundImageUrl: "",
  unsplashSourceUrl: "https://source.unsplash.com/random/?desert,racing",
  staticBackgroundImageUrls: [
    "https://images.unsplash.com/photo-1528492203317-873be2c040dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80"
  ],
  drawerOpen: false,
  loading: false,
  playerID: ""
});

export { setGlobalState, useGlobalState };
