import React from 'react';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link'

import { centerContent } from '../theme/common'

const About: React.FC = () => {

    return (
      <Box sx={centerContent}>

        <Card>
          <CardContent>
            lovingly crafted by: <br/>
            <Link href="https://happiness.engineering"><strong>happiness.engineering</strong></Link>
          </CardContent>
        </Card>

      </Box>

  );

}

export default About;


