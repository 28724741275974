import React from 'react';
import Box from '@mui/material/Box'

import { centerContent } from '../theme/common'

const NotFound: React.FC = () => {

    return (
      <Box sx={centerContent}>

            <span>nothing here, sorry.</span>

      </Box>

  );

}

export default NotFound;

