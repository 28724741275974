import React from 'react';
import Box from '@mui/material/Box'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';

import { centerContent } from '../theme/common'

const Contact: React.FC = () => {

    return (
      <Box sx={centerContent}>

        <Card>
          <CardContent>
            for more information, please contact: <br/>
            <Link href="mailto:info@turn1.tv"><strong>info@turn1.tv</strong></Link>
          </CardContent>
        </Card>

      </Box>
  );

}

export default Contact;

