import React, { CSSProperties } from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MailIcon from '@mui/icons-material/Mail'
import AboutIcon from '@mui/icons-material/Info'
import HomeIcon from '@mui/icons-material/Home'
import Tooltip from '@mui/material/Tooltip'
import { Typography } from '@mui/material'

import { useNavigate } from "react-router-dom";

import './App.css';

import Routes from "./routes";

import { useGlobalState } from './store/store'

const App: React.FC = () => {

    const navigate = useNavigate()

    const [bgUrl] = React.useState('')

    const [mainBackgroundImageUrl] = useGlobalState('mainBackgroundImageUrl')
    
    const barRight = {
        marginLeft: 0,
        display: 'flex',
        width: '100vw',
        justifyContent: 'flex-end',
    }

    const staticBgUrl = "https://images.unsplash.com/photo-1528492203317-873be2c040dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80"

    const appStyle: CSSProperties = {
      display: "flex",
      margin: 0,
      height: "100vh",
      width: "100wh",
      textAlign: "center",
      opacity: 0.9,
      backgroundImage: 'url(' + staticBgUrl + ')',
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      overflowX: "auto"
    }

    return (

        <Box style={appStyle}>

        <Box>

            <CssBaseline />

            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: "rgba(0,0,0,0.5)"
                }}
            >
 
                <Toolbar>
                    <IconButton
                        color="inherit"
                        onClick={() => navigate('/')}
                        edge="start"
                    >
                        <HomeIcon/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Turn1
                    </Typography>
                    <Box sx={barRight}>
                        <IconButton
                        color="inherit"
                        onClick={() => navigate('about')}
                        edge="start"
                        sx={{
                            marginRight: 2
                        }}
                        >
                            <AboutIcon/>
                        </IconButton>
                        <IconButton
                        color="inherit"
                        onClick={() => navigate('contact')}
                        edge="start"
                        sx={{
                            marginRight: 2
                        }}
                        >
                            <MailIcon/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>

            <main>
                    <Routes />
            </main>
        </Box>

        <Tooltip 
        title={mainBackgroundImageUrl}
        >
            <IconButton
                style={{
                position: "absolute",
                bottom: 0,
                right: 0
                }}
                onClick={() => window.open(bgUrl)}>
            <AboutIcon
                fontSize="large"
            />
            </IconButton>
        </Tooltip>


        </Box>

  );

}

export default App;

