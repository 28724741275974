import React, { useState } from 'react';
import Box from '@mui/material/Box'
import { AgGridReact } from 'ag-grid-react';
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-material.css';


import YoutubeEmbed from './youtube'
import "./youtube.css"

import { useGlobalState } from '../store/store'

import { centerContent } from '../theme/common'

const Home: React.FC = () => {

    const [playerID, setPlayerID] = useGlobalState('playerID');

    const [rowData] = useState([
      {number: "7", driver: "Bryce Menzies", stream: "Offline", video_id: "J_3l9gchRkg"},

    ]);

    const [columnDefs] = useState([
        { field: 'number', width: 75, headerName: "#", headerClass: 'headerpadclass' },
        { field: 'driver', width: 150, cellStyle: { 'padding-left': 0} },
        { field: 'stream', cellStyle: { 'padding-left': 0} },
        { field: 'video_id', hide: true}
    ])

    const handleClick = (event: any) => {

      console.log(event.data.video_id)
      setPlayerID(event.data.video_id)

    }

    const rowstyle = { fontSize: 11 };

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (

      <Box sx={centerContent}>


          {
            matches ? 
              <Grid container rowSpacing={1} marginTop={5}>

              <Grid item md={3} sm={12} style={{minWidth:250}}>
                <div className="ag-theme-material" style={{height:"100%", textAlign:"left"}}>
                <AgGridReact
                  rowData={rowData} 
                  columnDefs={columnDefs}
                  suppressCellFocus={true}
                  onRowClicked={(event: any) => handleClick(event)}
                  autoSizePadding={0}
                  suppressAutoSize={true}
                  rowStyle={rowstyle}
                />
                </div>
              </Grid>
              <Grid item md={9} sm={12}>

                <YoutubeEmbed embedID={playerID} />

              </Grid>
              
              </Grid>
            :
            <div style={{paddingTop:75}}>
              <Grid container>
                <Grid item xs={12} style={{width:"100vw", height: "30vh"}}>
                <div className="ag-theme-material" style={{height:"100%", textAlign:"left"}}>
                <AgGridReact
                  rowData={rowData} 
                  columnDefs={columnDefs}
                  suppressCellFocus={true}
                  onRowClicked={(event: any) => handleClick(event)}
                  autoSizePadding={0}
                  suppressAutoSize={true}
                  rowStyle={rowstyle}
                />
                </div>
                </Grid>
              </Grid>
              <Grid container>
              <Grid item xs={12}>

              <YoutubeEmbed embedID={playerID} />

              </Grid>
             </Grid>
             </div>
          }


      </Box>

  );

}

export default Home;

